import React from 'react';
// import ShopifyDataContextProvider from '~/context/ShopifyDataContext';
// import ShopifyStoreProvider from '~/context/StoreContext';
import ThemeContextProvider from './src/context/ThemeContext';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
    <ThemeContextProvider>
        {element}
    </ThemeContextProvider>
);

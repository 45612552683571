import themes, { ThemeInterface } from './themes';

export interface CollectionInfoInterface {
    name: string;
    id: string;
    theme: ThemeInterface;
}

export const PRODUCT_PREVIEW_LIMIT = 3;

export const STOREFRONT_API_URL = `https://${process.env.GATSBY_SHOP_URL}/api/2022-04/graphql.json`;

export const NEWS_PAGE = {
    theme: themes.white,
};

export const COLLECTION_NAMES = [
    'collection_bracelets',
    'collection_necklaces',
    'collection_anklets',
    'collection_bellychains',
    'collection_custom',
    'collection_limited',
];

export const COLLECTIONS: {
    [key: string]: CollectionInfoInterface;
} = {
    collection_custom: {
        name: 'collection_custom',
        id: 'gid://shopify/Collection/263895908386',
        theme: themes.pink,
    },

    collection_bracelets: {
        name: 'collection_bracelets',
        id: 'gid://shopify/Collection/177012670498',
        theme: themes.green,
    },

    collection_necklaces: {
        name: 'collection_necklaces',
        id: 'gid://shopify/Collection/177012768802',
        theme: themes.purple,
    },

    collection_anklets: {
        name: 'collection_anklets',
        id: 'gid://shopify/Collection/177012801570',
        theme: themes.yellow,
    },

    collection_bellychains: {
        name: 'collection_bellychains',
        id: 'gid://shopify/Collection/177012834338',
        theme: themes.red,
    },

    collection_limited: {
        name: 'collection_limited',
        id: 'gid://shopify/Collection/603556708686',
        theme: themes.green,
    },
};

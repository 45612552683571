export interface ThemeInterface {
    header: String;
    headerDesktop: String;
    text: String;
    background: String;
    textRGB: String;
    buttonText: String;
}

interface Themes {
    [key: string]: ThemeInterface;
}

const themes: Themes = {
    pink: {
        header: '#000',
        headerDesktop: '#000',
        text: '#000',
        textRGB: '0, 0, 0',
        buttonText: '#FFF',
        background: '#DF8898',
    },
    green: {
        header: '#000',
        headerDesktop: '#000',
        text: '#000',
        textRGB: '0, 0, 0',
        buttonText: '#FFF',
        background: '#D2DCAC',
    },
    purple: {
        header: '#FFF',
        headerDesktop: '#FFF',
        text: '#FFF',
        textRGB: '255, 255, 255',
        buttonText: '#000',
        background: '#3D31F5',
    },
    yellow: {
        header: '#000',
        headerDesktop: '#000',
        text: '#000',
        textRGB: '0, 0, 0',
        buttonText: '#FFF',
        background: '#F0C447',
    },
    red: {
        header: '#FFF',
        headerDesktop: '#FFF',
        text: '#FFF',
        textRGB: '255, 255, 255',
        buttonText: '#000',
        background: '#D50000',
    },
    white: {
        header: '#000',
        headerDesktop: '#000',
        text: '#000',
        textRGB: '0, 0, 0',
        buttonText: '#FFF',
        background: '#FFF',
    },
    productPage: {
        header: '#FFF',
        headerDesktop: '#000',
        text: '#000',
        textRGB: '0, 0, 0',
        buttonText: '#FFF',
        background: '#FFF',
    },
    black: {
        header: '#FFF',
        headerDesktop: '#FFF',
        text: '#FFF',
        textRGB: '255, 255, 255',
        buttonText: '#000',
        background: '#000',
    },
};

themes.default = themes.white;

export default themes;

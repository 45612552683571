import { LargeTextStyle } from '~/utils/styles';

function Title({ children, level = 'h2' }: { children: any, level: string }) {
    switch (level) {
    case 'h1':
        return <h1 css={LargeTextStyle}>{children}</h1>;
    case 'h3':
        return <h3 css={LargeTextStyle}>{children}</h3>;
    case 'h4':
        return <h4 css={LargeTextStyle}>{children}</h4>;
    case 'h2':
    default:
        return <h2 css={LargeTextStyle}>{children}</h2>;
    }
}

export default Title;

import React, { FC, useContext } from 'react';
import { Global, css } from '@emotion/react';
import Header from '~/components/Header';
import { ThemeContext } from '~/context/ThemeContext';
import ShopifyStoreProvider from '~/context/StoreContext';
import ShopifyDataContextProvider from '~/context/ShopifyDataContext';

interface LayoutProps {
    children: FC;
}

const Layout = ({ children }: LayoutProps) => {
    const { theme } = useContext(ThemeContext);

    const styles = css(`
        body {
            margin: 0;
            font-family: 'HelveticaNeue-Medium', 'Helvetica Neue', 'Helvetica', 'Arial';
            font-size: 16px;
            letter-spacing: 0.02em;
            background-color: ${theme.background};
            color: ${theme.text};
            //transition: opacity 0.25s ease-in-out, background-color 0.085s ease-in-out, color 0.085s ease-in-out;
            //transition: opacity 0.25s ease-in-out;
            transition: background-color 0.085s ease-in-out, color 0.085s ease-in-out;
            opacity: 0;

            .wf-active & {
                opacity: 1;
            }

            /*
            .wf-inactive & {
                opacity: 1;
            }
            */
        }
        html {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    `);

    return (
        <ShopifyStoreProvider>
            <ShopifyDataContextProvider>
                <Global
                    styles={styles}
                />

                <Header />
                {children}
            </ShopifyDataContextProvider>
        </ShopifyStoreProvider>
    );
};

export default Layout;

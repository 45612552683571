import styled from '@emotion/styled';

const Svg = styled.svg`
    vertical-align: top;
`;

function CrossIcon() {
    return (
        <Svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1.41406" width="36.1508" height="2" transform="rotate(45 1.41406 0)" fill="currentColor" />
            <rect x="27" y="1.41431" width="36.1508" height="2" transform="rotate(135 27 1.41431)" fill="currentColor" />
        </Svg>
    );
}

export default CrossIcon;

import React, { useCallback, useContext, useState } from 'react';
import { useLocation } from '@reach/router';
import reduce from 'lodash/reduce';
import { StoreContext } from '~/context/StoreContext';
import { ThemeContext } from '~/context/ThemeContext';
// import MenuToggle from './MenuToggle';
import HamburgerIcon from '~/components/HamburgerIcon';
import { ShopifyDataContext } from '~/context/ShopifyDataContext';
import LogoTitle from '~/components/LogoTitle';
import Navigation from './Navigation';
import {
    StyledHeader,
    MenuLink,
    MenuToggle,
    BagIcon,
} from './styles';

const useQuantity = () => {
    const { store: { checkout } } = useContext(StoreContext);
    const items = checkout ? checkout.lineItems : [];
    const total = reduce(items, (acc, item) => acc + item.quantity, 0);
    return [total !== 0, total];
};

const Header = () => {
    const { theme/* , setTheme */ } = useContext(ThemeContext);
    const shopifyData = useContext(ShopifyDataContext);
    const [hasItems, quantity] = useQuantity();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const siteTitle = shopifyData.site?.siteMetadata?.title;

    const toggleMenu = useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen]);

    const onMenuItemClick = () => {
        setMenuOpen(false);
    };

    const isFrontPage = location.pathname === '/';

    return (
        <>
            <StyledHeader>
                <div className="left">
                    <MenuToggle className="desktop" onClick={toggleMenu}>
                        Menu
                    </MenuToggle>
                    <MenuToggle className="mobile" onClick={toggleMenu}>
                        <HamburgerIcon theme={theme} />
                    </MenuToggle>
                </div>
                <div className="center">
                    <MenuLink
                        theme={theme}
                        to="/"
                        onClick={() => {
                            setMenuOpen(false);
                        }}
                    >
                        <LogoTitle level={isFrontPage ? 'h1' : 'h2'}>{siteTitle}</LogoTitle>
                    </MenuLink>
                </div>
                <div className="right">
                    <MenuLink theme={theme} to="/cart">
                        <div className="desktop">
                            Bag
                            {' '}
                            <BagIcon>{quantity}</BagIcon>
                        </div>
                        <div className="mobile">
                            {hasItems && <span>{quantity}</span>}
                        </div>
                    </MenuLink>
                </div>
            </StyledHeader>

            <Navigation
                siteTitle={siteTitle}
                closeMenu={() => setMenuOpen(false)}
                menuOpen={menuOpen}
                onMenuItemClick={onMenuItemClick}
            />
        </>
    );
};

export default Header;

import styled from '@emotion/styled';
import { breakpoints } from '~/utils/styles';
import { ThemeInterface } from '~/utils/themes';

interface ThemeProps {
    theme: ThemeInterface;
}

const Icon = styled.svg(({ theme }: ThemeProps) => `
    width: 26px;
    height: 26px;
    color: ${theme.header || 'currentColor'};

    @media ${breakpoints.lg} { 
        width: 12px;
        height: 12px;
    }
`);

function HamburgerIcon({ theme }: ThemeProps) {
    return (
        <Icon theme={theme} width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="none">
            <rect y="25" width="27" height="2" />
            <rect y="13" width="27" height="2" />
            <rect width="27" height="2" />
        </Icon>
    );
}

export default HamburgerIcon;

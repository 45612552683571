import { useContext } from 'react';
import { ShopifyDataContext } from '~/context/ShopifyDataContext';
import { ThemeContext } from '~/context/ThemeContext';
import { getCollectionLink } from '~/utils';
import { COLLECTIONS, COLLECTION_NAMES } from '~/utils/constants';
import CrossIcon from '~/components/CrossIcon';
import { LargeTextStyle } from '~/utils/styles';
import themes, { ThemeInterface } from '~/utils/themes';
import {
    CloseMenuButton, Menu, MenuBackdrop, MenuHeader, MenuLink, MenuList, MenuListLink, MenuOverlay,
} from './styles';

interface MenuItemInterface {
    href: string;
    title: string;
    theme: ThemeInterface;
}

interface NavigationProps {
    siteTitle: string;
    menuOpen: boolean;
    closeMenu: () => void;
    onMenuItemClick: (item: MenuItemInterface) => void;
}

const Navigation = ({
    siteTitle, menuOpen, closeMenu, onMenuItemClick,
}: NavigationProps) => {
    const { collections } = useContext(ShopifyDataContext);
    const { theme } = useContext(ThemeContext);

    const MENU_ITEMS = [
        {
            title: 'News',
            href: '/news',
            theme: themes.black,
        },
        ...COLLECTION_NAMES.map((collectionName) => ({
            title: collections[collectionName].title,
            href: getCollectionLink(collections[collectionName]),
            theme: COLLECTIONS[collectionName].theme,
        })),
        {
            title: 'About',
            href: '/#about',
            theme: themes.white,
        },
    ];

    return (
        <MenuOverlay isOpen={menuOpen}>
            <MenuBackdrop
                onClick={closeMenu}
            />
            <Menu theme={theme}>
                <MenuHeader>
                    <CloseMenuButton onClick={closeMenu}>
                        <CrossIcon />
                        <span className="desktop">
                            Close
                        </span>
                    </CloseMenuButton>

                    <MenuLink
                        theme={theme}
                        className="mobile"
                        to="/"
                        onClick={closeMenu}
                    >
                        <div css={[LargeTextStyle, 'color: white']}>
                            {siteTitle}
                        </div>
                    </MenuLink>
                </MenuHeader>
                <MenuList>
                    {MENU_ITEMS.map((item) => (
                        <li key={item.href}>
                            <MenuListLink
                                theme={item.theme}
                                to={item.href}
                                title={item.title}
                                onClick={() => onMenuItemClick(item)}
                            >
                                {item.title}
                            </MenuListLink>
                        </li>
                    ))}
                </MenuList>
            </Menu>
        </MenuOverlay>
    );
};

export default Navigation;

import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';
import {
    CheckoutInterface, LineItemInterface, StoreContextInterface, StoreInterface,
} from '~/utils/types';

const client = Client.buildClient({
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN || '',
    domain: 'shop.slic.world',
});

const initialStoreState: StoreInterface = {
    client,
    adding: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
};

export const StoreContext = React.createContext<StoreContextInterface>({
    store: initialStoreState,
});

const ShopifyStoreProvider = ({ children }: { children: any }) => {
    const [store, updateStore] = useState(initialStoreState);

    let isRemoved = false;

    useEffect(() => {
        const initializeCheckout = async () => {
            // Check for an existing cart.
            const isBrowser = typeof window !== 'undefined';
            const existingCheckoutID = isBrowser
                ? localStorage.getItem('shopify_checkout_id')
                : null;

            const setCheckoutInState = (checkout: CheckoutInterface) => {
                if (isBrowser) {
                    localStorage.setItem('shopify_checkout_id', `${checkout.id}`);
                }

                updateStore((prevState: any) => ({
                    ...prevState,
                    checkout,
                }));
            };

            const createNewCheckout = () => store.client.checkout.create();
            const fetchCheckout = (id: string) => store.client.checkout.fetch(id);

            if (existingCheckoutID) {
                try {
                    const checkout = await fetchCheckout(existingCheckoutID);
                    // Make sure this cart hasn’t already been purchased.
                    if (!isRemoved && !checkout.completedAt) {
                        setCheckoutInState(checkout as CheckoutInterface);
                        return;
                    }
                } catch (e) {
                    localStorage.setItem('shopify_checkout_id', '');
                }
            }

            const newCheckout = await createNewCheckout();
            if (!isRemoved) {
                setCheckoutInState(newCheckout as CheckoutInterface);
            }
        };

        initializeCheckout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.client.checkout]);

    useEffect(() => () => { isRemoved = true; }, []);

    const providerValue: StoreContextInterface = {
        store,
        addVariantToCart: (variantId, quantity, customAttributes = []) => {
            if (variantId === '' || !quantity) {
                console.error('Both a size and quantity are required.');
                return null;
            }

            updateStore((prevState) => ({ ...prevState, adding: true }));

            const { checkout } = store;
            const checkoutId = checkout?.id;

            if (checkoutId) {
                const lineItemsToUpdate: LineItemInterface[] = [
                    { variantId, quantity, customAttributes },
                ];

                return store.client.checkout
                    .addLineItems(checkoutId, lineItemsToUpdate)
                    .then((newCheckout: any) => {
                        updateStore((prevState) => ({
                            ...prevState,
                            checkout: newCheckout,
                            adding: false,
                        }));
                    });
            }

            return null;
        },
        removeLineItem: ({ checkout }, checkoutID, lineItemID) => checkout
            .removeLineItems(checkoutID, [lineItemID])
            .then((res: any) => {
                updateStore((prevState) => ({ ...prevState, checkout: res }));
            }),
        updateLineItem: (
            { checkout }, checkoutID, lineItemID, quantity, customAttributes = [],
        ) => {
            const lineItemsToUpdate = [
                { id: lineItemID, quantity, customAttributes },
            ];

            return checkout
                .updateLineItems(checkoutID, lineItemsToUpdate)
                .then((res: any) => {
                    updateStore((prevState) => ({ ...prevState, checkout: res }));
                });
        },
    };

    return (
        <StoreContext.Provider
            value={providerValue}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default ShopifyStoreProvider;

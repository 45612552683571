import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { breakpoints, CommonMenuStyle, LargeTextStyle } from '~/utils/styles';
import { ThemeInterface } from '~/utils/themes';

interface ThemeProps {
    theme: ThemeInterface;
}

export const MenuHeader = styled.div`
    border-bottom: 2px solid white; 
    padding: 16px 25px;
    display: flex;
    align-items: center;

    @media ${breakpoints.lg} {
        // position: sticky;
        padding: 16px;
    }
`;

export const CloseMenuButton = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: inherit;
    line-height: inherit;
    appearance: none;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;

    svg {
        margin-right: 10px;
    }

    .desktop {
        display: none;
    }

    @media ${breakpoints.lg} {
        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }
    }
`;

export const StyledHeader = styled.header`
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 20;
    display: flex;
    justify-content: space-between;
    user-select: none;
    padding: 16px 25px 0;

    > div {
        flex: 1;
        display: flex;
    }

    .left {
        justify-content: flex-start;
    }

    .center {
        justify-content: center;
    }

    .right {
        justify-content: flex-end;
    }

    @media ${breakpoints.lg} {
        position: sticky;
        padding: 16px 16px 0;
    }
`;

export const MenuToggle = styled.button`
    ${CommonMenuStyle};
    appearance: none;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    font-family: inherit;
    outline: none;
    color: inherit;

    &.desktop {
        display: none;
    }

    &.mobile {
        display: inline;
    }

    @media ${breakpoints.lg} { 
        &.desktop {
            display: inline;
        }

        &.mobile {
            display: none;
        }

        svg {
            path {
                stroke-width: 1.5;
            }
        }
    }
`;

export const NavLogo = styled.div`
    ${LargeTextStyle}
`;

export const MenuBackdrop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    z-index: 1;
    transition: all 250ms ease-in-out;
    opacity: 0;
    pointer-events: none;
`;

export const Menu = styled.div(({ theme }: ThemeProps) => `
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: black;
    color: white;
    z-index: 2;
    transition-property: opacity, transform;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    opacity: 0;
    width: 100%;

    @media ${breakpoints.lg} {
        color: ${theme.headerDesktop || 'inherit'};
        width: auto;
    }
`);

export const MenuList = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: auto;
    height: 100%;
`;

export const MenuListLink = styled(Link)(({ theme }: ThemeProps) => [
    LargeTextStyle,
    `  
        display: block;
        line-height: 1;
        padding: 18px 25px 16px;
        text-decoration: none;
        color: ${theme.text};
        background: ${theme.background};
        letter-spacing: 0.02em;
        font-size: 40px;

        @media ${breakpoints.lg} {
            padding: 18px 16px 16px;
        }
    `,
]);

export const MenuOverlay = styled.div(({ isOpen }: { isOpen: boolean; }) => `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    pointer-events: none;

    ${MenuBackdrop} {
        pointer-events: ${isOpen ? 'auto' : 'none'};
        opacity: ${isOpen ? 0.75 : 0};
    }

    ${Menu} {
        pointer-events: ${isOpen ? 'auto' : 'none'};
        transform: ${isOpen ? 'none' : 'translateY(-100%)'};
        opacity: 1;

        @media ${breakpoints.lg} {
            transform: ${isOpen ? 'none' : 'translateX(-100%)'};
        }
    }
`);

export const MenuLink = styled(Link)(({ theme }: ThemeProps) => [
    CommonMenuStyle,
    `
        color: ${theme.header || 'inherit'};

        @media ${breakpoints.lg} {
            color: ${theme.headerDesktop};
        }

        .desktop {
            display: none;
        }

        .mobile {
            display: block;
            font-family: 'BrancherBeta-Regular';
            letter-spacing: 0.095em;
            line-height: 0.85;
            margin: 0;
            font-size: 38px;
            font-weight: normal;

            @media ${breakpoints.lg} {
                font-size: 60px;
            }
        }

        @media ${breakpoints.lg} { 
            .desktop {
                display: block;
            }

            .mobile {
                display: none;
            }
        }

        ${MenuHeader} &.mobile {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            pointer-events: none;

            @media ${breakpoints.lg} {
                display: none;
            }
        }
    `,
]);

export const BagIcon = styled.span`
    &:before {
        content: '(';
        margin-right: 2px;
    }

    &:after {
        content: ')';
        margin-left: 2px;
    }
`;

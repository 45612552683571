// https://levelup.gitconnected.com/create-a-css-theme-switcher-using-gatsby-and-tailwindcss-aca85f93bd1f
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { getPreviewCollection } from '~/utils';
import { PRODUCT_PREVIEW_LIMIT } from '~/utils/constants';
import { CollectionInterface, NewsArticleInterface, Node } from '~/utils/types';

interface ShopifyDataContextInterface {
    site: {
        siteMetadata: {
            title: string;
        }
    }
    latestNewsArticle?: NewsArticleInterface;
    setLatestNewsArticle: (a: NewsArticleInterface) => void;
    storeFront: {
        articles: {
            edges: Node<NewsArticleInterface>[];
        };
    }
    collections: {
        [key: string]: CollectionInterface;
    }
}

export const ShopifyDataContext = React.createContext({} as ShopifyDataContextInterface);

const ShopifyDataContextProvider = ({ children }: any) => {
    const globalShopifyData = useStaticQuery(graphql`
        fragment CollectionFields on ShopifyCollection {
            id
            shopifyId: storefrontId
            title
            handle
            products {
                id
                shopifyId: storefrontId
                title
                handle
                productType
                description
                descriptionHtml
                tags
                metafields {
                    namespace
                    key
                    value
                }
                priceRange: priceRangeV2 {
                    minVariantPrice {
                        amount
                        currencyCode
                    }
                    maxVariantPrice {
                        amount
                        currencyCode
                    }
                }
                options {
                    id: shopifyId
                    name
                    values
                }
                variants {
                    id: shopifyId
                    shopifyId: storefrontId
                    title
                    price
                    availableForSale
                    selectedOptions {
                        name
                        value
                    }
                }
                media {
                    ... on ShopifyMediaImage {
                        id
                        image {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }

        fragment ShopifyArticleFields on Storefront_Article {
            id
            handle
            title
            publishedAt
            content
            contentHtml
            image {
                originalSrc
                src
                width
                height
            }
            seo {
                title
                description
            }
            excerpt
            tags
        }
        
        query {
            site {
                siteMetadata {
                    title
                }
            }

            collection_custom: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/263895908386" }) {
                ...CollectionFields
            }

            collection_limited: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/603556708686" }) {
                ...CollectionFields
            }

            collection_bracelets: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/177012670498" }) {
                ...CollectionFields
            }

            collection_necklaces: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/177012768802" }) {
                ...CollectionFields
            }

            collection_anklets: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/177012801570" }) {
                ...CollectionFields
            }

            collection_bellychains: shopifyCollection(storefrontId: { eq: "gid://shopify/Collection/177012834338" }) {
                ...CollectionFields
            }
        }
    `);
    const {
        collection_custom: custom,
        collection_bracelets: bracelets,
        collection_necklaces: necklaces,
        collection_anklets: anklets,
        collection_bellychains: bellychains,
        collection_limited: limited,
    } = globalShopifyData;
    const productLimit = PRODUCT_PREVIEW_LIMIT + 1; // Use preview amount + 1 to limit data
    const collectionCustom = getPreviewCollection(custom, productLimit);
    const collectionBracelets = getPreviewCollection(bracelets, productLimit);
    const collectionNecklaces = getPreviewCollection(necklaces, productLimit);
    const collectionAnklets = getPreviewCollection(anklets, productLimit);
    const collectionBellychains = getPreviewCollection(bellychains, productLimit);
    const collectionLimited = getPreviewCollection(limited, productLimit);

    const [latestArticle, setLatestArticle] = useState<NewsArticleInterface>();

    return (
        <ShopifyDataContext.Provider
            value={{
                site: globalShopifyData.site,
                storeFront: globalShopifyData.storeFront,
                latestNewsArticle: latestArticle,
                setLatestNewsArticle: setLatestArticle,
                collections: {
                    collection_custom: collectionCustom,
                    collection_bracelets: collectionBracelets,
                    collection_necklaces: collectionNecklaces,
                    collection_anklets: collectionAnklets,
                    collection_bellychains: collectionBellychains,
                    collection_limited: collectionLimited,
                },
            }}
        >
            {children}
        </ShopifyDataContext.Provider>
    );
};

export default ShopifyDataContextProvider;

import React, { useCallback, useState } from 'react';
import themes, { ThemeInterface } from '~/utils/themes';

interface ThemeContextInterface {
    theme: ThemeInterface,
    setTheme: (theme: ThemeInterface) => void,
}

export const ThemeContext = React.createContext({
    theme: themes.default,
    setTheme: () => null,
} as ThemeContextInterface);

const ThemeContextProvider = ({ children }: any) => {
    const [theme, setTheme] = useState(themes.white);

    const setNewTheme = useCallback(
        (newTheme: ThemeInterface) => {
            setTheme(newTheme);
        }, [],
    );

    return (
        <ThemeContext.Provider value={{ theme, setTheme: setNewTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-product-shopify-product-handle-tsx": () => import("./../../../src/pages/product/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-product-shopify-product-handle-tsx" */),
  "component---src-pages-shopify-collection-handle-tsx": () => import("./../../../src/pages/{ShopifyCollection.handle}.tsx" /* webpackChunkName: "component---src-pages-shopify-collection-handle-tsx" */),
  "component---src-templates-legal-page-template-tsx": () => import("./../../../src/templates/LegalPageTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-page-template-tsx" */)
}

